<script lang="ts" setup>
import type { PlayersActivity, PlayersActivityGame } from "~/types";

const defaultTypesDictionary = new Map([
	["jackpot", "jackpot"],
	["daily-wheel-accrual", "fortune wheel"]
]);

const props = defineProps<{ winner: PlayersActivity }>();
defineEmits<{ (event: "openGame", item: PlayersActivityGame): void }>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { isMobile } = useDevice();

const imageSrcPath = computed(() => {
	if (defaultTypesDictionary.has(props.winner.type)) {
		return `/nuxt-img/activity-feed/${props.winner.type}.png`;
	}

	return `${baseImageUrl}${props.winner.data?.logo}`;
});
</script>
<template>
	<div class="feed-card">
		<AAnimationSkeleton v-if="'skeleton' in winner" class="skeleton-wrapper">
			<ASkeleton width="100%" height="100%" />
		</AAnimationSkeleton>

		<template v-else>
			<div class="content" @click="$emit('openGame', winner.data)">
				<NuxtImg class="logo" :src="imageSrcPath" format="webp" :alt="winner.type" loading="lazy" quality="100" />
				<div class="info">
					<AText class="winner-name text-cocoa" variant="topeka" :modifiers="['ellipsis', 'semibold', 'uppercase']">
						{{ winner.name || winner.nickname }} {{ winner.surname }}
					</AText>
					<AText class="winner-title" variant="topeka" :modifiers="['semibold', 'condensed', 'uppercase']" as="div">
						<i18n-t
							v-if="defaultTypesDictionary.has(winner.type)"
							:keypath="defaultTypesDictionary.get(winner.type) ?? ''"
						>
							<template #key><br /></template>
						</i18n-t>
						<div v-else v-html="winner.data?.title" />
					</AText>

					<div class="prize-pool" :class="{ column: winner.type === 'daily-wheel-accrual' }">
						<MPrizeFund
							v-if="winner.type === 'win'"
							:variant="winner.gameMode === 'SweepStakes' ? 'entries' : 'coins'"
							is-svg
							:iconSize="isMobile ? 16 : 18"
							:icon="winner.gameMode === 'SweepStakes' ? 'entries' : 'coins'"
						>
							<AText :variant="isMobile ? 'topeka' : 'toledo'" :modifiers="['semibold']">
								{{ numberFormat(winner?.data?.winAmount ?? 0) }}
							</AText>
						</MPrizeFund>

						<MPrizeFund
							v-if="winner.type === 'jackpot'"
							:variant="winner.gameMode === 'SweepStakes' ? 'entries' : 'coins'"
							is-svg
							:iconSize="isMobile ? 16 : 18"
							:icon="winner.gameMode === 'SweepStakes' ? 'entries' : 'coins'"
						>
							<AText :variant="isMobile ? 'topeka' : 'toledo'" :modifiers="['semibold']">
								{{ numberFormat(winner?.data?.amount ?? 0) }}
							</AText>
						</MPrizeFund>

						<MPrizeFund
							v-if="winner.type === 'bigwin'"
							is-svg
							variant="coins"
							:iconSize="isMobile ? 16 : 18"
							:icon="winner.gameMode === 'SweepStakes' ? `games/big-win-fc` : `games/big-win-gc`"
						>
							<AText :variant="isMobile ? 'topeka' : 'toledo'" :modifiers="['semibold']">
								{{ numberFormat(winner?.data?.big_win_coefficient ?? 0) }}
							</AText>
						</MPrizeFund>

						<template v-if="winner.type === 'daily-wheel-accrual'">
							<MPrizeFund v-if="winner?.data?.coins" variant="coins" is-svg :iconSize="isMobile ? 16 : 18" icon="coins">
								<AText :variant="isMobile ? 'topeka' : 'toledo'" :modifiers="['semibold']">
									{{ numberFormat(winner.data.coins) }}
								</AText>
							</MPrizeFund>
							<MPrizeFund
								v-if="winner?.data?.entries"
								variant="entries"
								is-svg
								:iconSize="isMobile ? 16 : 18"
								icon="entries"
							>
								<AText :variant="isMobile ? 'topeka' : 'toledo'" :modifiers="['semibold']">
									{{ numberFormat(winner?.data?.entries || 0) }}
								</AText>
							</MPrizeFund>
						</template>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
<style scoped lang="scss">
.feed-card {
	padding: gutter(1);
	height: 90px;
	display: flex;
	flex-direction: column;
	position: relative;
	border-radius: 12px;
	background: var(--craiova);

	@include media-breakpoint-down(md) {
		padding: gutter(0.5);
		height: 71px;
	}

	.logo {
		border-radius: 12px;
		width: 56px;
		height: 63px;
		object-fit: cover;
		@include media-breakpoint-up(lg) {
			width: 64px;
			height: 74px;
		}
	}

	.content {
		display: flex;
		align-items: center;
		gap: gutter(2);

		@include media-breakpoint-down(md) {
			gap: gutter(1.5);
			height: 100%;
		}
	}

	.info {
		display: flex;
		flex-direction: column;
	}

	.winner-name {
		line-height: 18px;

		@include media-breakpoint-down(md) {
			font-size: 10px;
			line-height: 14px;
		}
	}

	.winner-title {
		margin-bottom: gutter(0.5);
		@include media-breakpoint-down(md) {
			font-size: 10px;
			line-height: 9px;
		}
	}

	.prize {
		gap: gutter(0.5);

		&-pool {
			display: flex;
			gap: gutter(0.25);

			&.column {
				flex-direction: column;
			}
		}
	}
}
</style>
